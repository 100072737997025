import { type PortableTextComponentProps } from '@portabletext/react'
import cx from 'classnames'
import { useState, useContext } from 'react'

import { type SanityAddToCartButton } from '@data/sanity/queries/types/content'
import { StringsContext } from '@lib/strings-context'

import Alert from '@components/alert'
import AddToCartButton from '@components/buttons/add-to-cart-button'
import {
  getButtonColor,
  getButtonIconAlignment,
  getButtonSize,
  getButtonVariant,
} from '@components/buttons/button'
import SimplePortableText from '@components/simple-portable-text'

type AddToCartButtonValue = Pick<
  SanityAddToCartButton,
  'productVariant' | 'style' | 'text'
>

const PortableTextAddToCartButton = ({
  value,
}: PortableTextComponentProps<AddToCartButtonValue>) => {
  const {
    productVariant,
    text,
    style: { variant, size, color, icon, iconAlignment, isFullWidth },
  } = value

  const strings = useContext(StringsContext)

  const [isAddToCartError, setIsAddToCartError] = useState(false)

  return (
    <>
      <AddToCartButton
        productVariant={productVariant}
        variant={getButtonVariant(variant)}
        size={getButtonSize(size)}
        color={getButtonColor(color)}
        icon={icon}
        iconAlignment={getButtonIconAlignment(iconAlignment)}
        onAddToCartError={() => setIsAddToCartError(true)}
        className={cx('btn', {
          'w-full': isFullWidth,
        })}
      >
        {text}
      </AddToCartButton>

      {isAddToCartError && (
        <div key="error" className="mt-2">
          <Alert error>
            <SimplePortableText
              className="rc rc-alert rc-error"
              content={strings.cartAddToCartErrorMessage}
            />
          </Alert>
        </div>
      )}
    </>
  )
}

export default PortableTextAddToCartButton
