import { type PortableTextComponentProps } from '@portabletext/react'

interface TableRow {
  _type: 'tableRow'
  _key: string
  cells: string[]
}

interface Table {
  _type: 'table'
  _key: string
  rows?: TableRow[]
}

const Table = ({ value }: PortableTextComponentProps<Table>) => {
  const { _key, rows } = value

  if (!rows || rows.length < 1) {
    return
  }

  return (
    <div className="table">
      <table>
        <thead>
          <tr>
            {rows[0].cells?.map((cell, cellIndex) => (
              <th key={`${_key}-tableCell-${cellIndex}`}>{cell}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.slice(1, rows.length).map((row, rowIndex) => (
            <tr key={`${_key}-${row._type}-${rowIndex}`}>
              {row.cells?.map((cell, cellindex) => (
                <td key={`${_key}-tableCell-${cellindex}`}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
export default Table
