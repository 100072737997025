import { type PortableTextComponentProps } from '@portabletext/react'
import cx from 'classnames'

import { type SanityQuote } from '@data/sanity/queries/types/content'

import Photo from '@components/photo'

type QuoteValue = Pick<
  SanityQuote,
  'author' | 'authorImage' | 'alignment' | 'size' | 'accentDot'
> & {
  text: string
}

const Quote = ({ value }: PortableTextComponentProps<QuoteValue>) => {
  const { text, author, authorImage, alignment, size, accentDot } = value

  return (
    <div
      className={cx('quote', {
        [`text-${alignment}`]: alignment,
      })}
    >
      <blockquote
        className={cx(
          'text-current text-2xl sm:text-3xl lg:leading-tight mb-0',
          {
            'lg:text-4xl': size === 'small',
            'lg:text-5xl lg:before:mb-8': size === 'large',
            'before:content-[""] before:block before:w-[30px] before:h-[30px] before:rounded-full before:mx-auto before:mb-6':
              alignment === 'center',
            'before:bg-current': !accentDot,
            'before:bg-pageText': accentDot,
          }
        )}
      >
        {text}
      </blockquote>

      <div
        className={cx({
          'mt-6': !authorImage?.asset,
          'mt-8': authorImage?.asset,
          'lg:mt-12': size === 'large',
        })}
      >
        {authorImage?.asset && (
          <div className="w-[60px] h-[60px] inline-block relative rounded-full overflow-hidden mb-3">
            <Photo image={authorImage} fill />
          </div>
        )}
        {author && <p className="text-current text-base">{author}</p>}
      </div>
    </div>
  )
}

export default Quote
